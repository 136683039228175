import isEmpty from "lodash/isEmpty";

import { DATE_FILTER_FORMAT } from "@lib/constants";
import { format, parse } from "@lib/utils/dateFnsWrapper";
import {
	ANNUAL_PLANS,
	AVAILABLE_PLANS,
	CHECKOUT_VARIANTS,
	PLANS_WITH_FLAC_STREAMING,
	PURCHASE_CATEGORIES,
	RECURLY_STATUS,
} from "@lib/constants/subscriptions";

/**
 * Checks if user has subscription data on record
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if there is a pre-existing record of subscription
 */
export const hasSubscriptionRecord = (mySubscription?: MySubscription): boolean =>
	Boolean(
		!isEmpty(mySubscription) &&
		!isEmpty(mySubscription.status) &&
		!isEmpty(mySubscription.subscription),
	);

/**
 * Checks if a plan matches current user's subscription
 * @param {Plan} plan - Plan that is being checked
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if plan code matches current subscription plan code
 */
export const isCurrentPlan = (plan: Plan, mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const isCurrent = mySubscription?.subscription?.bundle?.plan_code === plan.plan_code;
	return hasRecord && isCurrent;
};

/**
 * Checks if user has an active subscription
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user is currently in trial period
 */
export const hasActiveSubscription = (mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const isActive = mySubscription?.status?.includes(RECURLY_STATUS.ACTIVE) ?? false;
	return hasRecord && isActive;
};

/**
 * Checks if user has canceled their subscription
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user's current subscription is canceled
 */
export const hasCanceledSubscription = (mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const isCanceled = mySubscription?.status?.includes(RECURLY_STATUS.CANCELED) ?? false;
	return hasRecord && isCanceled;
};

/**
 * Checks if user's subscription has expired
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user is currently in trial period
 */
export const hasExpiredSubscription = (mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const hasExpired = mySubscription?.status?.includes(RECURLY_STATUS.EXPIRED) ?? false;
	return hasRecord && hasExpired;
};

/**
 * Checks if user is currently in trial
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user is currently in trial period
 */
export const isInTrial = (mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const inTrial = mySubscription?.status?.includes(RECURLY_STATUS.IN_TRIAL) ?? false;
	return hasRecord && inTrial;
};

/**
 * Checks if user is downgrading their subscription
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user's status includes a downgrade record
 */
export const isDowngradingSubscription = (mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const isDowngrading = mySubscription?.status?.some((status) =>
		status.startsWith(RECURLY_STATUS.DOWNGRADE),
	);
	return hasRecord && !!isDowngrading;
};

/**
 * Checks if user is downgrading their subscription
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user's status includes a downgrade record
 */
export const isDowngradingToPlan = (plan: Plan, mySubscription?: MySubscription): boolean => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const isDowngradingToPlan = mySubscription?.status?.includes(
		`${RECURLY_STATUS.DOWNGRADE}${plan.plan_code}`,
	) ?? false;
	return hasRecord && isDowngradingToPlan;
};

/**
 * Extracts the plan code the user is downgrading to
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {string | null} - Returns the plan code if downgrading, otherwise null
 */
export const getDowngradePlanCode = (mySubscription?: MySubscription): string | null => {
	const hasRecord = hasSubscriptionRecord(mySubscription);
	const downgradeStatus = mySubscription?.status.find((status) =>
		status.startsWith(RECURLY_STATUS.DOWNGRADE),
	);

	if (!hasRecord || !downgradeStatus) return null;

	const planCode = downgradeStatus.split(" ").slice(2).join(" ");

	if (planCode && AVAILABLE_PLANS.includes(planCode)) return planCode;

	return null;
};

/**
 * Retrieves user's subscription end date. This date is also used as the upcoming
 * billing date for recurring subscriptions.
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {Date|undefined} - Returns Date object if user has an active subscription
 */
export const getBillingDate = (mySubscription?: MySubscription): Date | undefined => {
	const endDate = mySubscription?.subscription?.end_date;
	const formattedEndDate = endDate && endDate instanceof Date ?
		endDate.toISOString().slice(0, 10) :
		endDate;
	return formattedEndDate ? parse(formattedEndDate, DATE_FILTER_FORMAT, new Date()) : undefined;
};

/**
 * Retrieves user's subscription end date. This date is also used as the upcoming
 * billing date for recurring subscriptions.
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {string|undefined} - Returns stringifed date in format "Jan 15, 2022"
 */
export const getBillingDateString = (mySubscription?: MySubscription): string | undefined => {
	const billingDate = getBillingDate(mySubscription);
	return billingDate && format(billingDate, "PP");
};

/**
 * Checks if user used up their free trial
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user used up their free trial
 */
export const hasUsedFreeTrial = (mySubscription?: MySubscription): boolean =>
	hasSubscriptionRecord(mySubscription);

/**
 * Checks if user is still eligible for a free trial period
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if user is still eligible for a free trial
 */
export const isEligibleForFreeTrial = (mySubscription?: MySubscription): boolean =>
	!hasUsedFreeTrial(mySubscription);

/**
 * Checks if a plan is user's current plan with an active subscription
 * @param {Plan} plan - Plan that is being checked
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true if plan code matches current subscription's plan code
 */
export const isActivePlan = (plan: Plan, mySubscription?: MySubscription): boolean =>
	isCurrentPlan(plan, mySubscription) &&
	hasActiveSubscription(mySubscription);

/**
 * Checks if a plan offers free trial period
 * @param {Plan} plan - Plan that is being checked
 * @returns {boolean} - Returns true if plan offers a free trial period
 */
export const isFreeTrialAvailableForPlan = (plan: Plan): boolean =>
	plan.plan_trial_interval_length > 0;

/**
 * Checks if user is eligible for a free trial as well as if a plan that user
 * selected offers free trial. Both conditionts must be met for user to start
 * a free trial period.
 * @param {Plan} plan - Plan that is being checked
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} -  Returns true if user has not yet used up their trial period and
 * 						 selected plan offers free trial period
 */
export const isEligibleForFreeTrialForPlan = (plan: Plan, mySubscription?: MySubscription): boolean =>
	isEligibleForFreeTrial(mySubscription) &&
	isFreeTrialAvailableForPlan(plan);

/**
 * Checks if a plan code belongs to an annual plan
 * @param {string} planCode - Unique plan code
 * @returns {boolean} - Returns true if plan code belongs to an annual plan
 */
export const isAnnualPlanCode = (planCode?: string): boolean =>
	planCode ? ANNUAL_PLANS.includes(planCode) : false;

/**
 * Checks if a plan is an annual plan
 * @param {Plan} plan - Plan that is being checked
 * @returns {boolean} - Returns true if plan is annual
 */
export const isAnnualPlan = (plan: Plan): boolean => isAnnualPlanCode(plan.plan_code);

/**
 * Check if the user is eligible to stream FLAC
 * @param {MySubscription} mySubscription - User's current subscription
 * @returns {boolean} - Returns true user is eligible to stream FLAC format
*/
export const hasFlacStreamingAvailable = (mySubscription?: MySubscription): boolean =>
	PLANS_WITH_FLAC_STREAMING.includes(mySubscription?.subscription?.bundle?.plan_code ?? "");

/**
 * Removes decimals if value is a full number, otherwise enforces 2 decimals
 * Example
 *  - 10.00 > 10
 *  - 10.5 > 10.50
 *  - 10.03 > 10.03
 *  - 10.12345 > 10.12
 *  - 10 	> 10
 * @param {string|number} value - Value to be formated
 * @returns {string} - Formated value to be displayed
 */
export const formatDisplayPrice = (value: string | number): string => {
	const numericValue = typeof value === "string" ? parseFloat(value) : value;

	if (isNaN(numericValue)) {
		return String(value);
	}

	if (Number.isInteger(numericValue)) {
		return numericValue.toString();
	}

	// Truncate the number to two decimal places, ensuring no rounding errors
	const truncatedValue = Math.floor(numericValue * 100) / 100;

	// If the number has one decimal place, ensure two decimal places
	if (truncatedValue * 10 === Math.floor(truncatedValue * 10)) {
		return truncatedValue.toFixed(2);
	}

	// Return up to 2 decimal places, removing unnecessary trailing zeros
	return truncatedValue.toFixed(2).replace(/\.?0+$/, "");
};

/**
 * Removes decimals if price is a full number, otherwise enforces 2 decimals
 * Example
 *  - USD$10.00 > USD$10
 *  - USD$10.5 > USD$10.50
 *  - USD$10.03 > USD$10.03
 *  - USD$10.12345 > USD$10.12
 *  - USD$10 	> USD$10
 * @param {Plan} plan - Plan whose price is being parsed
 * @param {str} currency - Key used to retrieve a specific currency value
 * @returns {string} - Formatted value to be displayed
 */
export const formatCardDisplayPrice = (plan: Plan, currency: string): string => {
	const priceWithCurrency = plan.plan_price[currency];
	const numericValue = parseFloat(priceWithCurrency.replace(/[^\d.-]/g, ""));
	const formattedPrice = formatDisplayPrice(numericValue);
	return priceWithCurrency.replace(/[0-9.-]+/, formattedPrice);
};

/**
 * Calculates price based on coupon discount
 * @param {number} price - Plan price
 * @param {number} discount - Coupon discount %, valid values in range [0, 100]
 * @returns {string} - Plan price after coupon has been applied. In case of an invalid discount value,
 * we just return original price.
 */
export const formatTotalAfterCoupon = (price: number, discount: number): string => {
	if (discount <= 0 || discount > 100 || isNaN(discount)) {
		return price.toFixed(2);
	}
	const couponAmount = parseFloat((price * (discount / 100)).toFixed(2));
	const totalAfterCoupon = price - couponAmount;
	return totalAfterCoupon.toFixed(2);
};

/**
 * Determines product variant for data layer
 * @param {boolean} props.isCurrentPlan - true if this is user's current plan
 * @param {boolean} props.isUpdate - true if user is updating their current subscription or
 * 							   		 false if user has no active subscription
 * @param {boolean} props.isEligibleForTrial - true if user is eligible for free trial for a plan
 * @returns {string} - Returns corresponding string for dataLayer subscription event(s)
 */
export const getCheckoutVariant = ({
	isCurrentPlan,
	isUpdate,
	isEligibleForTrial,
}: {
	isCurrentPlan: boolean;
	isUpdate: boolean;
	isEligibleForTrial: boolean;
}): string => {
	return isCurrentPlan ?
		CHECKOUT_VARIANTS.SUBSCRIPTION_NO_CHANGE :
		isUpdate ?
			CHECKOUT_VARIANTS.SUBSCRIPTION_UPDATE :
			isEligibleForTrial ?
				CHECKOUT_VARIANTS.TRIAL :
				CHECKOUT_VARIANTS.NEW_SUBSCRIPTION;
};

/**
 * Determines purchase variant for data layer
 * @param {boolean} props.isEligibleForTrial - true if user is eligible for free trial for a plan
 * @returns {string} - Returns corresponding string for dataLayer subscription event(s)
 */
export const getPurchaseVariant = ({
	isEligibleForTrial,
}: {
	isEligibleForTrial: boolean;
}): string =>
	isEligibleForTrial ?
		PURCHASE_CATEGORIES.FREE_TRIAL :
		PURCHASE_CATEGORIES.SUBSCRIPTION;
