import Image from "next/image";
import Link from "next/link";
import { MouseEventHandler, useRef } from "react";
import { Trans, useTranslation } from "next-i18next";
import { cls } from "@lib/css";
import Button from "@components/Button";
import Marquee from "@components/Marquee";
import ImageFallback from "@components/core/Image/ImageFallback";
import { TableTypes } from "@bptypes/tables";
import { theme } from "@styles/theme";
import { CartItem } from "@models/Cart";
import { Track } from "@models/track";
import { ItemLoader } from "@components/loaders";
import { Audioformat } from "@models/audio-format";
import { renderAudioFormatName } from "@lib/constants/audio-format";
import { MAX_FREE_DOWNLOADS } from "@lib/constants/subscriptions";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import LabelLink from "@components/shared/Labels/LabelLink";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Control } from "@components/interaction/Play/Play.style";
import { DownloadButton } from "@components/interaction/DownloadButtons";
import { AddToCart, AddToPlaylist, AddToQueue, Play, Tooltip } from "@components/interaction";
import { AUDIO_FORMATS, AUDIO_FORMAT_IDS, TRACK_PLACEHOLDER } from "@lib/constants";
import { ArtistNames, renderArtistNames } from "@components/shared/Artists/ArtistNames";
import ReDownloadButton from "@components/interaction/ReDownloadButton/ReDownloadButton";
import { TrackAddIndicator } from "@components/tables/LibraryTable/LibraryTable.style";
import { ChartsTrackListItem } from "@components/lists/TracksList/TrackListItems/ChartsTrackListItem";
import { Artwork, CartMeta, Exclusive, ItemControls, MetaRow, MoreButton } from "@components/lists/Lists.shared.style";
import { AddTrackControls, Item, ItemActions, ItemButtons, ItemMeta, ItemNumber, LoaderWrapper, Spacer, TrackName } from "@components/lists/TracksList/TracksList.style";
import { UpgradeToCart } from "@components/interaction/UpgradeToCart";
import { getItemPrice } from "@lib/utils";

interface RenderTrackProps {
	track: Track;
	index: number;
	dragHandleProps?: DraggableProvidedDragHandleProps;
	isSubscribed: boolean;
	isPaginated: boolean;
	perPage: number;
	page: number;
	trackListType?: TableTypes;
	location?: string;
	dataTestId: string;
	chartDeleteTrack?: (trackId: number) => void;
	currentTrack?: Track;
	controls?: number;
	setControls: (id: number | undefined) => void;
	showArtwork?: boolean;
	forceHover?: boolean;
	isCartList?: boolean;
	isAddTrackModal?: boolean;
	exclusive: string;
	handlePlayTrackClick: (track: Track) => void;
	isCollection: boolean;
	handleSelectTrack: (e: React.ChangeEvent<HTMLInputElement>, track: Track) => void;
	queueTracks?: Track[];
	showNumbers?: boolean;
	filteredTracks: Track[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	selectedItems?: number[];
	handleTrackClick: (track: Track, index: number) => void;
	audioFormats: Record<number, Audioformat>;
	setFinalRedownloadModalCallback: (callback: (() => MouseEventHandler) | null) => void;
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	isDownloads?: boolean;
	filenamePattern: string;
	chartAddTrack?: (trackId: number) => void;
	chartAddTrackLoading?: number[];
	chartAddedTracks?: number[];
	formatSelector?: (cartItem: CartItem<Track>) => JSX.Element;
	isLoading?: boolean;
}

const FALLBACK_URL = TRACK_PLACEHOLDER.href;

export const TrackListItem = (
	{
		track,
		index,
		dragHandleProps,
		isSubscribed,
		isPaginated,
		perPage,
		page,
		trackListType,
		location,
		dataTestId,
		chartDeleteTrack,
		currentTrack,
		controls,
		setControls,
		showArtwork,
		forceHover,
		isCartList,
		isAddTrackModal,
		exclusive,
		handlePlayTrackClick,
		isCollection,
		handleSelectTrack,
		queueTracks,
		showNumbers,
		filteredTracks,
		onItemSelectionChange,
		selectedItems,
		handleTrackClick,
		audioFormats,
		setFinalRedownloadModalCallback,
		itemCartActions,
		isDownloads,
		filenamePattern,
		chartAddTrack,
		chartAddTrackLoading = [],
		chartAddedTracks = [],
		formatSelector,
		isLoading,
	}: RenderTrackProps,
) => {
	const { t } = useTranslation();
	const actionsContainerRef = useRef<HTMLDivElement>(null);
	const shouldMarkDownloadAsComplete =
		!isSubscribed && !track.initial_download && track.received_status;

	const currentNumberOfDownloads = track.order_item_download_try_count || 0;
	const trackIndex = isPaginated ? perPage * (page - 1) + index : index;
	const hasOneDownloadAttemptLeft = !isSubscribed && (currentNumberOfDownloads === MAX_FREE_DOWNLOADS - 1);
	const hasNoMoreDownloadAttempts = !isSubscribed && currentNumberOfDownloads >= MAX_FREE_DOWNLOADS;

	// Assume track is enabled if the flag is not set
	const enabled =
		track.enabled || track.enabled === undefined || track.enabled === null;
	const isUsingUpgradedAudioFormat =
		track.audio_format &&
		AUDIO_FORMAT_IDS.filter(
			(item) =>
				item.name === AUDIO_FORMATS.aiff || item.name === AUDIO_FORMATS.wav,
		)
			.map((item) => item.id)
			.includes(track?.audio_format?.id);

	return trackListType === "CHART" ?
			(
				<ChartsTrackListItem
					key={`list-track-${track.id}`}
					location={location}
					data-testid={dataTestId}
					track={track}
					index={index}
					chartDeleteTrack={chartDeleteTrack}
					dragHandleProps={dragHandleProps}
				/>
			) :
			(
				<Item
					key={`list-track-${track.id}`}
					data-testid={dataTestId}
					className={cls(
						currentTrack && currentTrack.id === track.id ? "current" : undefined,
						controls && controls === track.id ? "actions" : undefined,
						!showArtwork ? "no-artwork" : undefined,
						forceHover ? "force-hover" : undefined,
						isCartList ? "cart-list" : undefined,
						!enabled ? "tombstoned" : undefined,
					)}
				>
					{showArtwork && !isAddTrackModal && (
						<Link
							href={`/release/${track.release.slug}/${track.release.id}`}
							prefetch={false}
							title={track.release.name}
							className="artwork"
						>
							<Artwork>
								<Image
									src={dynamicImageUrl({ imageUri: track.release.image?.uri, size: "sm" })}
									alt={track.release.name}
									width={80}
									height={80}
								/>
								{track.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
						</Link>
					)}

					{isAddTrackModal && (
						<Control onClick={() => handlePlayTrackClick(track)}>
							<Artwork>
								<ImageFallback
									src={track.release.image?.uri}
									srcSize="sm"
									alt={track.release.name}
									width={80}
									height={80}
									fallbackSrc={FALLBACK_URL}
									blurDataURL={FALLBACK_URL}
									placeholder="blur"
								/>
								{track.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
							<SpriteIcon
								id="track-play-inline"
								width="11"
								height="16"
								className="album-cover"
							/>
						</Control>
					)}

					<ItemMeta>
						{isCollection ?
								(
									<ItemNumber>
										{track.pre_order ?
												(
													<div style={{ display: "inline-block" }}>
														<SpriteIcon id="pre-order" />
													</div>
												) :
												(
													enabled && !isAddTrackModal && (
														<input
															type="checkbox"
															value={track.id}
															onChange={(e) => handleSelectTrack(e, track)}
															checked={queueTracks?.some(
																(selectedTrack: Track) => selectedTrack.id === track.id,
															)}
														/>
													)
												)}
									</ItemNumber>
								) :
							null}
						{!showNumbers && !isCollection && !isCartList ? <Spacer /> : null}
						{!isCartList && showNumbers && (
							<>
								<ItemNumber>{trackIndex + 1}</ItemNumber>
								<div className="play-hover">
									<Play tracks={[track]} virtualQueue={filteredTracks} />
								</div>
							</>
						)}
						{isCartList && track.cart_item_data && onItemSelectionChange && (
							<ItemNumber
								onClick={() => {
									onItemSelectionChange(
										track.cart_item_data?.id || 0,
										!selectedItems?.some((i: number) => i === track.cart_item_data?.id),
									);
								}}
							>
								{selectedItems?.some((i: number) => i === track.cart_item_data?.id) ?
										(
											<SpriteIcon id="checkbox-selected" />
										) :
										(
											<SpriteIcon id="checkbox" />
										)}
							</ItemNumber>
						)}
						<MetaRow>
							<Link
								href={`/track/${track.slug}/${track.id}`}
								prefetch={false}
								title={track.name}
								onClick={() => handleTrackClick(track, index)}
							>
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<TrackName>
										{track.name} <span>{track.mix_name}</span>{" "}
									</TrackName>
								</Marquee>
							</Link>
							{track.artists?.length > 0 && (
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<ArtistNames>
										{renderArtistNames(track.artists, { location })}
									</ArtistNames>
								</Marquee>
							)}
							{!isCartList && (
								<Marquee overlayElementSelector={`[data-overlayfor="track-${track.id}"]`}>
									<LabelLink label={track.release.label} location={location} withMarquee />
								</Marquee>
							)}
							{isCartList && (
								<CartMeta>
									<span>
										{track.cart_item_data &&
											getItemPrice({ item: track.cart_item_data, audioFormatsObject: audioFormats })}
									</span>
									<span>
										<Trans>
											{renderAudioFormatName(
												{ audioFormats: Object.values(audioFormats), audioFormatId: track.cart_item_data?.audio_format_id, purchaseTypeId: track.purchase_type_id },
											)}
										</Trans>
									</span>
								</CartMeta>
							)}
						</MetaRow>
						<ItemActions data-overlayfor={`track-${track.id}`} ref={actionsContainerRef}>
							{
								isCartList ?
										(
											isLoading ?
													(
														<LoaderWrapper>
															<ItemLoader />
														</LoaderWrapper>
													) :
													(
														<div className="cart-actions">
															<span className="playlist">
																<AddToPlaylist tracks={[track]} />
															</span>
															{!isCollection && track.cart_item_data && (
																<>
																	<div>
																		{itemCartActions && itemCartActions(track.cart_item_data)}
																	</div>
																	<div>
																		{formatSelector && formatSelector(track.cart_item_data)}
																	</div>
																</>
															)}
														</div>
													)) :
										(
											<div className="actions">
												<span className="play">
													<Play tracks={[track]} virtualQueue={filteredTracks} />
												</span>
												<span className="queue">
													<AddToQueue tracks={[track]} />
												</span>
												<span className="playlist">
													<AddToPlaylist tracks={[track]} />
												</span>
												{isCollection && (
													<span>
														<ReDownloadButton
															track={track}
															buttonState={!track.received_status ? "ADDED" : "ADD"}
															disabled={hasNoMoreDownloadAttempts}
															onClick={
																hasOneDownloadAttemptLeft ?
																		(handleDownload) => { setFinalRedownloadModalCallback(() => handleDownload); } :
																	undefined
															}
														/>
													</span>
												)}
												{!isCartList && !isCollection && (
													<span className="card">
														<AddToCart location={location} track={track} />
													</span>
												)}
												{
													!isCartList && isCollection && !isUsingUpgradedAudioFormat && !track?.pre_order && (
														<span className="card">
															<UpgradeToCart upgrade={track} />
														</span>
													)
												}
											</div>
										)
							}
						</ItemActions>

					</ItemMeta>

					{!isAddTrackModal && (
						<ItemControls>
							<ItemButtons>
								{!isDownloads && (
									<>
										<MoreButton className={!enabled ? "tombstoned" : ""}>
											<Play
												disabled={!enabled}
												tracks={[track]}
												virtualQueue={filteredTracks}
											/>
										</MoreButton>
										{!enabled ?
												(
													<span className="info">
														<Tooltip text={t("Collection.Unavailable")} position="left">
															<SpriteIcon
																className={!enabled ? "tombstoned selectable" : ""}
																id="info"
																width="16"
																height="16"
																stroke={theme.colors.neutrals.primary.white}
																fill={theme.colors.neutrals.primary.white}
															/>
														</Tooltip>
													</span>
												) :
												(
													<MoreButton
														data-testid="trackslist-item-more-button"
														onClick={() => {
															controls === track.id ?
																setControls(undefined) :
																setControls(track.id);
														}}
													>
														<SpriteIcon id="dots" />
													</MoreButton>
												)}
									</>
								)}
								{isDownloads && (
									<DownloadButton
										track={track}
										encodeStatus={track.encode_status_name}
										filenamePattern={filenamePattern}
										shouldMarkDownloadAsComplete={shouldMarkDownloadAsComplete}
									/>
								)}
							</ItemButtons>
						</ItemControls>
					)}

					{isAddTrackModal && chartAddTrack !== undefined && (
						<AddTrackControls>
							{chartAddTrackLoading.includes(track.id) ?
									(
										<TrackAddIndicator>
											<span className="arrow">
												<ItemLoader />
											</span>
										</TrackAddIndicator>
									) :
								chartAddedTracks.includes(track.id) ?
										(
											<TrackAddIndicator>
												<span className="arrow">
													<SpriteIcon id="check-circle" />
												</span>
											</TrackAddIndicator>
										) :
										(
											<Button
												data-testid="add-to-chart-button"
												type="primary"
												onClick={() => chartAddTrack(track.id)}
											>
												{t("Actions.Add")}
											</Button>
										)}
						</AddTrackControls>
					)}
				</Item>
			);
};
