import styled from "styled-components";

export const TableCell = styled.div.attrs(() => ({
	role: "cell",
}))`
  padding: 8px;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  &.p-0 {
    padding: 0;
  }

  &.px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .tombstoned {
    opacity: 0.15;
  }

  .selectable {
    &:hover {
      opacity: 1;
    }
  }
`;

export const TableRow = styled.div<{ tooltipText?: string; liveStream?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.liveStream ? "" : "space-between")};
  align-items: center;
  height: 40px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  margin: 0 0 2px;
  &.selected {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
  }

  &.tombstoned {
    background: ${(props) => props.theme.colors.neutrals.primary[700]};
    a {
      pointer-events: none;
    }
    &:hover {
      background: ${(props) => props.theme.colors.neutrals.primary[700]};
    }
  }

  &.add-tracks-modal {
    margin: 0 0 8px;
  }

  svg.album-cover {
    display: none;
    position: absolute;
    margin-left: 15px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.active};
    div.chart-cell {
      color: white;
    }
    svg.album-cover {
      display: inline;
    }
    position: relative;

    &:hover::before {
      display: ${(props) => props.tooltipText ?? "none"};
      content: "${(props) => props.tooltipText}";
      position: absolute;
      top: 100%;
      left: 50%;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      border-radius: 8px;
      transition: background 0.2s;
      z-index: 1;
      background: ${(props) => props.theme.colors.neutrals.primary[900]};
      color: ${(props) => props.theme.colors.neutrals.primary.white};
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: ${(props) => props.color || props.theme.colors.neutrals.primary[900]};
    }
  }

  div:nth-child(2) {
    flex-grow: 1;
  }

  &.tracks-table {
    div:nth-child(2) {
    flex-grow: 0;
    }
  }
`;

export const TableData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-transform: uppercase;
`;

export const Table = styled.div.attrs(() => ({
	role: "table",
}))<{ liveStream?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${(props) => (props.liveStream ? "0px" : "12px")};
  border-top: 1px solid ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};

  a:hover {
    text-decoration: underline;
  }
`;
